<template>
  <form 
    class="flex flex-wrap justify-end"
    @submit.prevent="submit" 
  >
    
    <PInput 
      class="w-1/2" 
      v-model="name" 
      :required="true"
      :disabled="isSubmitting"
      :label="$tk('Common.Address.Name')"
    ></PInput>

    <PInput 
      class="pl-4 w-1/2" 
      v-model="street" 
      :required="true" 
      :disabled="isSubmitting"
      :label="$tk('Common.Address.Street')">
    </PInput>

    <PPostCodePicker
      class="w-full mt-4" 
      :countryId="countryId"
      :postCode="postalCode"
      :disabled="isSubmitting"      
      @change="onPostCodeChanged"
    />

    <div 
      class="flex items-center mt-8 w-full"
      :class="{
        'justify-end': !address,
        'justify-between': address
      }"
    >

      <PCheckbox v-model="active" label="Aktiv" v-if="address" /> 
      <PButton 
        type="submit" 
        :disabled="!canSubmit"
        :loading="isSubmitting"
      >
        <PTranslation k="Common.Actions.Save" />        
      </PButton>

    </div>
  </form>
</template>

<script>

  import api from "../api"
  import { get } from "lodash"
    
  export default {

    data () {
      return {
        name: "",
        street: "",        
        postalCode: "",
        postalName: "",   
        active: true,
        postalNameFound: false,
        countryId: "",
        isSubmitting: false
      }
    },

    props: {
      address: {
        type: Object,
        default: null
      },
      locationId: {
        type: String,
        default: ""
      },
    },  
    
    computed: {
      canSubmit () {
        return this.name.trim() !== "" &&
               this.street.trim() !== "" &&
               this.postalCode.trim() !== "" &&
               this.postalName.trim() !== "" &&
               this.countryId.trim() !== ""
      }
    },

    methods: {

      async submit () {
        this.isSubmitting = true
        try {
          const id = get(this.address, "id", "")
          if (id) {
            await api.addressUpdate(
              id,
              this.locationId, 
              this.name, 
              this.street, 
              this.postalCode, 
              this.postalName,             
              this.countryId,
              !this.active
            )
          } 
          else {
            await api.addressPost(
              this.locationId, 
              this.name, 
              this.street, 
              this.postalCode, 
              this.postalName,             
              this.countryId
            )
          }
          this.$emit("success")
        } catch (e) {
          this.$notifyError(this.$tk("AddressCreate.ErrorMessage"), this.$formatApiError(e))
        } 
        this.isSubmitting = false       
      },

      onPostCodeChanged: function (postCode) {            
        this.countryId = postCode.countryId
        this.postalCode = postCode.postCode
        this.postalName = postCode.postPlace
      }
    },

    watch: {
      "address": {
        handler: function (val) {             
          if (val) { 
            this.name = val.name
            this.street = val.street
            this.countryId = val.countryId
            this.postalCode = val.postalCode
            this.active = !val.inactive
          }
        }, immediate: true
      }
    }
  }
</script>